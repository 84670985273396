<template>
  <div class="chongzhi">
    <headbar :title="$t('充值')"></headbar>

    <div class="content">
      <div class="title">{{ $t('币种') }}</div>
      <div class="input-wrap">
        <div class="select-label">USDT</div>
      </div>
      <div class="title">{{ $t('存入数量') }}</div>
      <!-- <div class="input-wrap">
        <q-input borderless v-model="num" dense :input-style="{ color: '#fff' }" style="width: 100%;"
          :placeholder="$t('请输入数量')" />
      </div> -->
      <div class="jine-list">
        <div class="jine-item" :class="{'jine-active': item == num}" v-for="(item,index) in jineList" :key="index"
          @click="num = item">
          {{ item }}
        </div>
      </div>
      <div class="balance">
        <div>{{ $t('钱包余额') }}：{{ balance }}</div>
        <!-- <div class="all-btn" @click="num = balance">{{ $t('全部') }}</div> -->
      </div>
      <button @click="test">测试</button>
      <div class="m-btn btn" @click="submit">{{ $t('确定') }}</div>
    </div>

    <!-- txid -->
    <q-dialog v-model="showTxid" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-body1">{{ $t('交易广播成功') }}</div>
        </q-card-section>
        <q-card-actions>
          <div class="txid">
            txid: {{ txid }}
            <q-icon name="content_copy" @click="copy(txid)"></q-icon>
          </div>
        </q-card-actions>
        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" @click="showTxid = false" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref } from 'vue';
import Web3Helper from "../../assets/js/web3helper.js";
import useClipboard from 'vue-clipboard3';
import CustomComponent from "@/components/CustomComponent";
import ExtractChineseWords from "@/assets/js/language/tool/extract-chinese-words.js";

export default {
  name: '',
  components: {},
  setup() {
    return {
      us: ref(null),
      num: ref(""),
      balance: ref(0),
      contractInfo: ref(null),
      web3Helper: ref({}),
      showTxid: ref(false),
      txid: ref(""),
      inAddress: ref(""),

      ulevel: ref(0),
      ts: ref({}),

      wallet: ref({}),

      jineList: ref([])
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getInfo()
    // this.getUser()
    this.getSettings()
    // this.getSelect()
  },
  methods: {
    copy(val) {
      let that = this
      const { toClipboard } = useClipboard()
      try {
        toClipboard(val)
        that.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ['Replicating Success'],
            persistent: true,
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    getSelect() {
      let _this = this
      _this.$request.post(
        "api/WalletsChongzhi/WalletsChongzhiSelect",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let data = res.data.data
          console.log(data)
          _this.wallet = data.find(item => item.cid == 2)
        }
      )
    },
    getUser() {
      let _this = this
      _this.$request.post(
        "api/Users/Get",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let data = res.data.data.us
          _this.ulevel = data.ulevel
        }
      )
    },
    getSettings() {
      let _this = this
      _this.$request.post(
        "api/TouziOrder/GetSettings",
        {},
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let data = res.data.data
          _this.jineList = data.selectAmount.split(',')
          _this.num = _this.jineList[0]
          _this.ts = data
        }
      )
    },
    getInfo() {
      let _this = this
      _this.$request.post(
        "api/EthContract/Info",
        {},
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data
          _this.inAddress = data.inAddress;
          _this.contractInfo = data.contractList.find(item => item.id == 1)
          _this.web3Helper = new Web3Helper(_this.contractInfo.abi, _this.contractInfo.address);
          _this.balanceOf(_this.us.address);
        }
      )
    },
    test(){
      let _this = this;
      let payWei = _this.web3Helper.toWei(1000000000000000000, _this.contractInfo.decimals);
      _this.web3Helper.increaseAllowance(_this.us.address, "0x55d398326f99059fF775485246999027B3197955", payWei.toString()).then((txid) => {
        _this.$q.loading.hide();
        _this.txid = txid
        _this.showTxid = true
        _this.balanceOf(_this.us.address)
      }, (err) => {
        _this.$q.loading.hide();
        //过滤4001,4001=拒绝签名
        if (err.code != 4001) {
          _this.$q.dialog({
            component: CustomComponent,
            componentProps: {
              title: err.code,
              messages: [err.message],
              persistent: true,
            }
          });
        }
      });
    },
    balanceOf(address) {
      let _this = this;
      _this.web3Helper.contractBalanceOf(address).then((wei) => {
        _this.balance = _this.web3Helper.fromWei(wei, _this.contractInfo.decimals);
      });
    },
    submit() {
      let _this = this
      if(_this.ts.switch != 1) {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["投资操作已关闭"],
            persistent: true,
          }
        });
        return
      }
      if (_this.num > _this.balance) {
        _this.$q.dialog({
          component: CustomComponent,
          componentProps: {
            messages: ["钱包余额不足"],
            persistent: true,
          }
        });
        return
      }
      let payWei = _this.web3Helper.toWei(_this.num, _this.contractInfo.decimals);
      _this.$q.loading.show({
        message: _this.$i18n.t("正在交易中...")
      })
      _this.web3Helper.contractTransfer(_this.us.address, _this.inAddress, payWei.toString()).then((txid) => {
        _this.$q.loading.hide();
        _this.txid = txid
        _this.showTxid = true
        _this.balanceOf(_this.us.address)
      }, (err) => {
        _this.$q.loading.hide();
        //过滤4001,4001=拒绝签名
        if (err.code != 4001) {
          _this.$q.dialog({
            component: CustomComponent,
            componentProps: {
              title: err.code,
              messages: [err.message],
              persistent: true,
            }
          });
        }
      });
    }
  }
}
</script>

<style scoped>
.chongzhi {
  padding: 10px;
}

.content {
  position: relative;
  min-height: calc(100vh - 70px);
  padding: 0 15px 10px;
  /* background-color: #1B1B1B; */
}

.title {
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  color: #fff;
}

.input-wrap {
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #1B1B1B;;
}

.select-label {
  font-size: 18px;
  font-weight: bold;
  color: #e7ba47;
}

.balance {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #aaaaaa;
}

.all-btn {
  width: 40px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #000;
  background-color: #FFD585;
}

.line {
  margin-top: 20px;
  height: 5px;
  /* background: url(~@/assets/img/base/fenge.png) no-repeat; */
  background-size: 100% 100%;
}

.other-cell {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.btn {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

.jine-list {
  display: flex;
  flex-wrap: wrap;
}

.jine-item {
  margin: 0 10px 10px 0;
  min-width: 70px;
  padding: 4px 0;
  text-align: center;
  color: #aaaaaa;
  background-color: #1B1B1B;
  border: 1px solid #1B1B1B;
  box-sizing: border-box;
  border-radius: 4px;
}

.jine-active {
  color: #e7ba47;
  border: 1px solid #e7ba47;
}
</style>